<template>
<div>
    <!-- My Information -->
    <div class="my-info mt-8">
        <div class="vx-row justify-center">
            <div class="vx-col lg:w-2/3 xl:w-2/3 w-full">
                <vx-card>
                    <div class="profile-icon">
                        <span class="main-icon">
                            <img src="../../assets/images/crm.svg" />
                        </span>
                        <div class="card-title flex-wrap justify-center">
                            <h2 class="text-white">{{ LabelConstant.headingLabelConfigureMyCRMIntegration }}</h2>
                            <!-- <vs-button title="Help" @click="popupActive=true" type="filled" icon-pack="feather" icon="icon-help-circle"></vs-button> -->
                        </div>
                    </div>
                    <div class="update-info-form">
                        <template>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <p class="mb-8">{{ LabelConstant.infoLabelCRM }}</p>
                                </div>
                                <div class="vx-col w-full">
                                    <vs-checkbox class="ml-0" v-model="crmData.CRM_Enabled">{{ LabelConstant.inputLabelEnableCRMIntegration }}</vs-checkbox>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <span class="vs-input--label">{{ LabelConstant.inputLabelSelectCRMSystem }}</span>
                                    <v-select v-model="crmData.CRM_Type" name="crmSystem" :options="crmsystemoptions" :reduce="crmsystemoptions => crmsystemoptions.value" :disabled="crmData.CRM_Enabled == true ?false:true" v-validate="'required'" :clearable="false" label="text" />
                                    <span class="text-danger text-sm">{{ errors.first('crmSystem') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-input class="w-full" icon-pack="feather" icon="icon-globe" icon-no-border :label="LabelConstant.inputLabelAPIURL" v-model="crmData.CRM_URL" name="apiurl" v-validate="'required'" v-if="crmSystem == 'ConnectWise' || crmSystem == 'SalesNexus'" />
                                    <span class="text-danger text-sm">{{ errors.first('apiurl') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-6">
                                <div class="vx-col w-full">
                                    <vs-input autocomplete="off" class="w-full" icon-pack="feather" icon="icon-tag" icon-no-border :label="LabelConstant.inputLabelSecurityToken" v-model="crmData.CRM_Key" v-validate="'required'" v-if="crmSystem !='Zoho'" name="securitytoken" />
                                    <span class="text-danger text-sm">{{ errors.first('securitytoken') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-6" v-if="crmSystem == 'ConnectWise' || crmSystem == 'SalesNexus'|| crmSystem == 'SalesForce.com'">
                                <div class="vx-col w-full">
                                    <vs-input autocomplete="off" class="w-full" icon-pack="feather" icon="icon-user" icon-no-border :label="LabelConstant.inputLabelUserName" v-model="crmData.CRM_User_Id" name="username" v-validate="'required'" />
                                    <span class="text-danger text-sm">{{ errors.first('username') }}</span>
                                </div>
                            </div>
                            <div class="vx-row mb-6" v-if="crmSystem == 'ConnectWise' || crmSystem == 'SalesNexus'|| crmSystem == 'SalesForce.com'">
                                <div class="vx-col w-full">
                                    <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock" icon-no-border :label="LabelConstant.inputLabelPassword" v-model="crmData.CRM_Password" name="CRM_Password" v-validate="'required'" />
                                    <span class="text-danger text-sm">{{ errors.first('CRM_Password') }}</span>
                                </div>
                            </div>
                            <div class="vx-row" v-if="crmSystem == 'SalesNexus'">
                                <div class="vx-col w-full mb-6 md:w-1/2">
                                    <vs-checkbox class="inline-flex" v-model="crmData.CRM_Push_General_Companies">{{ LabelConstant.inputLabelPushWebsiteVisitsToCRMForUntaggedVisitors }}</vs-checkbox>
                                </div>
                                <div class="vx-col w-full mb-6 md:w-1/2">
                                    <vs-checkbox class="inline-flex" v-model="crmData.CRM_Push_Tagged_Users">{{ LabelConstant.inputLabelPushWebsiteVisitsToCRMForTaggedVisitors }}</vs-checkbox>
                                </div>
                            </div>
                            <div class="vx-row">
                                <div class="vx-col w-full">
                                    <vs-table :data="defaultdata" class="mb-8 table-list crm-table" v-if="crmSystem == 'Zoho'" border="1">
                                        <template slot="thead">
                                            <vs-th>{{ LabelConstant.tableHeaderLabelSource }}</vs-th>
                                            <vs-th>{{ LabelConstant.tableHeaderLabelStatus }}</vs-th>
                                            <vs-th>{{ LabelConstant.tableHeaderLabelAction }}</vs-th>
                                        </template>
                                        <template slot-scope="{data}">
                                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                                <vs-td :data="tr.source">{{tr.source}}</vs-td>
                                                <vs-td :data="tr.status">
                                                    <p v-if="!zohoStatus"><span class="text-danger">{{ LabelConstant.statusConditionLabelNotConnected }}</span></p>
                                                    <p v-else><span class="text-success">{{ LabelConstant.statusConditionLabelConnected }}</span></p>
                                                </vs-td>
                                                <vs-td :data="tr.action">
                                                    <vs-button v-if="!zohoStatus" type="filled" class="small-btn bg-actionbutton" @click.prevent="getExternalAuthLink()">{{ LabelConstant.buttonLabelLinkAccount }}</vs-button>
                                                    <div v-else>
                                                        <vs-button type="filled" class="small-btn bg-actionbutton" @click.prevent="getExternalAuthLink()">{{ LabelConstant.buttonLabelUpdate }}</vs-button>
                                                        <vs-button color="danger" type="filled" class="small-btn ml-2" @click.prevent="deleteZohoAccount()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
                                                    </div>
                                                </vs-td>
                                            </vs-tr>
                                        </template>
                                    </vs-table>
                                </div>
                            </div>

                            <div class="vx-row">
                                <div class="vx-col w-full mt-6">
                                    <vs-button type="filled" class="mr-4 mb-2 small-btn" color="primary" @click.prevent="updateUserConfig()">{{ LabelConstant.buttonLabelUpdateMyCRMIntegration }}</vs-button>
                                    <vs-button color="grey" type="border" class="mb-0 md:mb-2 small-btn grey-btn" @click.prevent="cancelChanges()">{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                                </div>
                            </div>
                        </template>
                    </div>
                </vx-card>
            </div>
        </div>
    </div>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
import vSelect from "vue-select";
import {
    Validator
} from "vee-validate";
const dict = {
    custom: {
        crmSystem: {
            required: "The CRM system field is required"
        },
        apiurl: {
            required: "The API url field is required"
        },
        securitytoken: {
            required: "The security token field is required"
        },
        username: {
            required: "The user name field is required"
        },
        CRM_Password: {
            required: "The password field is required"
        },
        cityName: {
            required: "The city field is required"
        }
    }
};
Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        HelpModal,
        vSelect
    },
    data() {
        return {
            /* Popup */

            popupActive: false,
            /* Select */
            zohoStatus: false,
            enableCrm: false,
            crmsys: [],
            password: null,
            securitytoken: null,
            apiurl: null,
            username: null,
            CRM_Push_Tagged_Users: false,
            CRM_Push_General_Companies: false,
            crmSystem: null,
            crmData: {},
            crmsystemoptions: [{
                    value: "ConnectWise",
                    text: "ConnectWise"
                },
                {
                    value: "LeadMaster",
                    text: "LeadMaster"
                },
                {
                    value: "SalesForce.com",
                    text: "SalesForce.com"
                },
                {
                    value: "SalesNexus",
                    text: "SalesNexus"
                },
                {
                    value: "Zoho",
                    text: "Zoho"
                }
            ],
            defaultdata: [{
                source: "Zoho"
            }]
        };
    },
    mounted() {
        var that = this;
        window.addEventListener("focus", checkZohoStatus);

        function checkZohoStatus() {
            if (that.isEventListner) {
                that.getZohoStatus();
            }
            that.isEventListner = false;
        }
    },
    watch: {
        "crmData.CRM_Type"(val) {
            this.crmSystem = val;
        }
    },
    created() {
        this.getCurrentLoginUserDetails();
        this.getZohoStatus();
    },
    methods: {
        /* Popup Close */
        closepopup() {
            this.popupActive = false;
        },
        // get current user details
        async getCurrentLoginUserDetails() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigUser/GetLoggedInUser")
                .then(response => {
                    let data = response.data;
                    this.crmData = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //get zoho status
        async getZohoStatus() {
            this.$vs.loading();
            await this.axios
                .get("/ws/CRMIntegration/GetZohoCRMIntegrationStatus")
                .then(response => {
                    let data = response.data;
                    this.zohoStatus = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get zoho external auth link api 
        async getExternalAuthLink() {
            this.$vs.loading();
            let input = {
                ServiceTypeInfo: "zohocrm",
                LocationId: 0
            };
            await this.axios
                .post("/ws/CRMIntegration/RequestExternalLinkForAuthUpdate", input)
                .then(response => {
                    let data = response.data;
                    this.isEventListner = true;
                    window
                        .open(
                            data,
                            +"Zoho Account",
                            "toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=650, height=550,left = 300,top=100"
                        )
                        .focus();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // delete zoho account 
        async deleteZohoAccount() {
            this.$vs.loading();
            await this.axios
                .get("/ws/CRMIntegration/DeleteZohoCRMIntegrationConnection")
                .then(() => {
                    this.getZohoStatus();
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // update user config
        updateUserConfig() {
            this.$validator.validateAll().then(async result => {
                if (result) {
                    this.$vs.loading();
                    await this.axios
                        .post("/ws/ConfigUser/UpdateUser", this.crmData)
                        .then(() => {
                            this.getCurrentLoginUserDetails();
                            this.$vs.notify({
                                title: "Success",
                                text: "Your CRM integration information has been updated successfully.",
                                color: "success",
                                position: "top-right",
                                time: 4000
                            });
                            this.$vs.loading.close();
                        })
                        .catch(e => {
                            this.$vs.loading.close();
                            this.showError(e);
                        });
                }
            });
        },
        // cancel changes 
        cancelChanges() {
            this.getCurrentLoginUserDetails();
            this.$vs.notify({
                title: "Changes Reverted",
                text: "Your information has been restored to the original state.",
                color: "warning",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000
            });
        }
    }
};
</script>

<style scoped>
.zohotable {
    border-collapse: collapse;
}
</style>
